import { Box, Image } from "../../components/StyledComponent";
import { RoutePattern } from "../../routes/RoutePattern";
import VideoSlider from "../../components/YoutubeSlider/YoutubeSlider";
import SlickSlider from "../../components/SlickSlider/SlickSlider";

import { useEffect } from "react";
import axios from "axios";

const Videos = () => {
  return (
    <Box
      id={RoutePattern.VIDEOS}
      className="relative flex flex-col w-full"
    >
        <div
            className="text-4xl p-10 md:p-20 text-center bg-red-600"
        >
            videos
        </div>
        {/* <VideoSlider /> */}
        <SlickSlider />
    </Box>
  );
};

export default Videos;
