import { useSelector } from "react-redux";
import { Box } from "../../components/StyledComponent";
// import Showcase from "./Showcase";
// import Showcase from "./Showcase2";
import Showcase from "./Showcase3";
import Videos from "./Videos";
import Music from "./Music";
import Tour from "./Tour";
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navigation/Navigation";

export default function Home() {
  return (
    <Box className="text-white h-screen w-full">
      <Navigation />
      <Showcase />
      <Videos />
      <Music />
      {/* <Tour /> */}
      <Footer />
    </Box>
  );
}
