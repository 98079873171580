export const data = [
  {
    name: "privacy policy",
    to: "https://privacy.wmg.com/atlantic/privacy-policy",
    target: "_blank",
  },
  {
    name: "terms of us",
    to: "https://www.warnerrecords.com/terms-of-use",
    target: "_blank",
  },
  {
    name: "ad choices",
    to: "https://privacy.wmg.com/atlantic/privacy-policy#adchoices",
    target: "_blank",
  },
  {
    name: "cookies policy",
    to: "https://www.wminewmedia.com/cookies-policy/",
    target: "_blank",
  },
  {
    name: "Do Not Sell Or Share My Personal Information",
    class: "ot-sdk-show-settings cursor-pointer",
  },
];
