import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { data } from "./data";
import styled from "styled-components";

const SocialLink = styled.a``;

interface SocialIconProps {
  pandoraIconWidth?: string;
  iconWidth?: string;
}

export default function SocialIcons({
  iconWidth,
  pandoraIconWidth,
}: SocialIconProps) {
  return (
    <ul className="text-white flex absolute bottom-3 md:bottom-5">
      {data.map((item, idx) => (
        <SocialLink
          key={idx}
          href={item.link}
          rel="noreferrer"
          target="_blank"
          className="text-2xl md:text-3xl md:-mb-2 rounded-lg w-10 h-10 flex justify-center items-center md:mx-5"
        >
          {item.icon ? (
            <FontAwesomeIcon icon={item.icon} />
          ) : item.isPandora ? (
            <img
              src={item.img}
              className={pandoraIconWidth + "hidden"}
              style={{
                filter:
                  "invert(8%) sepia(99%) saturate(6379%) hue-rotate(355deg) brightness(90%) contrast(94%)",
              }}
            />
          ) : (
            <img
              src={item.img}
              className={iconWidth + "hidden"}
              style={{
                width: "45px",
                padding: "0 5px",
                filter:
                  "brightness(0) invert(1)",
              }}
            />
          )}
        </SocialLink>
      ))}
    </ul>
  );
}
