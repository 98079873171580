import { useEffect, useState, useRef } from "react";

const Preloader = () => {
  const [loaded, setIsLoaded] = useState(false);
  const Ref: any = useRef(null);
  const [timer, setTimer] = useState(0);
  const [timerExpired, setTimerExpired] = useState<boolean>(false);

  const getTimeRemaining = (e: any) => {
    const total = Date.parse(e) - Date.parse(Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
        total, seconds
    };
  }

  const startTimer = (e: any) => {
    let { total, seconds } = getTimeRemaining(e);
    if (total > 0) {
      setTimer(seconds)
    } else {
      setIsLoaded(true);
    }
  }

  const clearTimer = (e: any) => {
    setTimer(3);

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000)
    Ref.current = id;
  }

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + 3);
    return deadline;
  }

  useEffect(() => {
    window.onload = () => {
      clearTimer(getDeadTime());
      jQuery("#preloader-timer").css('display', 'flex');
    };

    jQuery("html, body").css('overflow', 'hidden');
  }, []);

  // useEffect(() => {
  //   window.onload = () => {
  //     // scroll top to 0

  //     setIsLoaded(document.readyState === "complete");

  //     setTimeout(() => {
  //       window.scrollTo({ top: 0 });
  //     }, 300);
  //   };
  // }, []);

  return (
    <div
      id="preloader-countdown"
      className="fixed top-0 left-0 w-full h-screen bg-black"
      style={{ zIndex: !loaded ? 123123123 : -123123123, display: !loaded ? 'block' : 'none' }}
    >
      <div id="preloader-timer" className="hidden h-screen w-full justify-center items-center text-white text-9xl -mt-20 md:mt-0 mb-20 md:mb-0">
        &nbsp;{timer}&nbsp;
      </div>
    </div>
  );
};

export default Preloader;
