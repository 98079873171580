import { data } from "./data";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components";
import { useState } from "react";
// import LOGO_IMG from "../../assets/images/logo.png";

const Container = styled.div`
  z-index: 40;
`;

export default function Navigation() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [colorChange, setColorChange] = useState<boolean>(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const menuItems = data.map((item, idx) =>
    // this statement is application only if we're at home
    item.isRoute ? (
      <ScrollLink
        key={idx}
        to={item.name} // id of the section
        smooth
        duration={1000}
        spy={true}
        onClick={() => {
          setIsActive(false);
        }}
        className="mx-2 md:mx-20 md:my-5 text-shadow cursor-pointer text-shadow text-sm md:text-2xl cursor-pointer whitespace-nowrap"
      >
        {item.name}
      </ScrollLink>
    ) : (
      <a
        key={idx}
        href={item.to} // route
        target="_blank"
        onClick={() => setIsActive(false)}
        className="mx-2 md:mx-20 md:my-5 text-shadow cursor-pointer text-shadow text-sm md:text-2xl cursor-pointer whitespace-nowrap"
      >
        {item.name}
      </a>
    )
  );

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };

  window.addEventListener('scroll', changeNavbarColor);

  return (
    <Container className={`mx-auto fixed justify-center items-center text-white bg-opacity-60 px-5 top-0 left-0 w-full py-4 md:py-2  lg:px-24
      ${colorChange ? "bg-black" : "bg-none"}
    `}>
      <div className="w-full md:flex justify-center items-center">
        {/* desktop navigation */}
        <div className="uppercase justify-between items-center flex-row flex m-auto">
          {menuItems}
        </div>
        {/* tablet navigation */}
        <ScrollLink
          to="home" // id of the section
          smooth
          duration={1000}
          spy={true}
          className="text-shadow cursor-pointer font-extrabold text-shadow text-sm md:text-5xl flex flex-row justify-center items-center mr-auto md:hidden"
        >
          {/* <img src={LOGO_IMG} alt="logo" className="w-10 md:w-16 mr-2" /> */}
        </ScrollLink>
        <div
          className={`uppercase justify-center items-center flex-col flex fixed top-0 bg-opacity-80 transition-all w-full h-screen bg-black md:hidden
            ${isActive ? "right-0" : "-right-full"}
        `}
        >
          {menuItems}
        </div>
        {/* burger */}
        {/* <div
          className="flex flex-col z-50 cursor-pointer md:hidden"
          aria-label="button"
          onClick={handleClick}
        >
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm ${
              isActive ? "rotate-45" : "rotate-0"
            } transition-all`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm mt-1 ${
              isActive ? "hidden" : "block"
            }`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm mt-1 ${
              isActive ? "-rotate-45 mt-0 absolute" : "rotate-0"
            } transition-all`}
          />
        </div> */}
      </div>
    </Container>
  );
}
