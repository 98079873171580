import {
    Box,
    // Button,
    // Heading,
    // Image,
  } from "../../components/StyledComponent";
  import {
    // faPause,
    // faPlay,
    faVolumeHigh,
    faVolumeMute,
    // faVolumeOff,
  } from "@fortawesome/free-solid-svg-icons";
  import { useEffect, useRef, useState } from "react";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { RoutePattern } from "../../routes/RoutePattern";
  import styled from "styled-components";
  import useScriptService from "../Hooks/useScriptService";
  import { Form, Formik } from "formik";
  import { InputField } from "../../components/StyledComponent/InputField";
  import DESKTOP_BG from "../../assets/videos/BELLA DOSE - BITE desktop.mp4";
  import MOBILE_BG from "../../assets/videos/BELLA DOSE - BITE mobile.mp4";
  import POSTER from "../../assets/images/BELLA DOSE - BITE mobile.gif";
  // import SITE_LOGO from "";
  import { isMobile } from "../../constants";
  import SocialIcons from "../../components/SocialIcons/SocialIcons";
  import Swal from 'sweetalert2'
  import TOUR_IMG from "../../assets/images/BD hero img.png";
  
  const VolumeBtn = styled.button`
  `;
  
  const VideoPlayer = styled.video`
  `;
  
  const VideoContainer = styled.div`
    // @media (max-width: 820px) {
    //   left: 50%;
    //   top: 50%;
    //   transform: translate(-50%,-50%);
    //   position: absolute;
    // }
  `;
  
  const ImgLogo = styled.img`
    width: 25%;
    margin: auto;
  
    @media (max-width: 1180px) {
      width: 35%;
    }
  
    @media (max-width: 845px) {
      width: 50%;
    }
  
    @media (max-width: 845px) and (max-height: 390px) {
      width: 25%;
      margin-top: 8rem;
    }
  `;
  
  const LogoContainer = styled.div`
  `;
  
  const EmailCapture = styled.div`
  `;
  
  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
  
  const Showcase = () => {
    useScriptService({ url: "https://widgetv3.bandsintown.com/main.min.js" });
    const [videoEnded, setVideoEnded] = useState<boolean>(false);
    const [muted, setMuted] = useState<boolean>(true);
    const [showModal, setShowModal] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const videoRef: any = useRef(null);
  
    useEffect(() => {
      let checkElement = setInterval(function() {
        if (jQuery('#preloader-countdown').css('display') === 'none') {
          // videoRef.current.play();
          jQuery("html, body").css('overflow', '');
          clearInterval(checkElement);
          
          Swal.fire({
            title: 'Sign up to our newsletter',
            text: 'Receive special offers and first look at new products.',
            input: 'email',
            inputAttributes: {
                autocapitalize: 'off'
            },
            inputPlaceholder: 'Enter your email address',
            allowEnterKey: false,
            allowEscapeKey: false,
            focusConfirm: false,
            returnFocus: false,
            showLoaderOnConfirm: true,
            confirmButtonColor: '#E03425',
            confirmButtonText: 'Submit',
            preConfirm: (email) => {
                var postData = {
                    Email: email,
                };
  
                $.ajax({
                  url: "https://script.google.com/macros/s/AKfycbwbRLv7YSFwmJNx4bU2XPXnMoLynrGa16I9CmsDui81ey2G2DjzBAw_ZTPAh_F9p6AdnQ/exec",
                  type: "post",
                  data: postData
                }).done(function() {
                  Swal.fire({
                    title: 'THANK YOU FOR SUBSCRIBING!',
                    icon: 'success',
                    iconColor: '#E03425',
                    allowEscapeKey: false,
                    confirmButtonColor: '#E03425',
                    customClass: {
                      confirmButton: 'newsletter__btn btn'
                    }
                  }).then(() => {
                  });
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire({
                  title: 'Submitting',
                  text: 'Please wait...',
                  didOpen: () => {
                    Swal.showLoading();
                  },
                })
              }
          });
        }
      }, 1000);
    }, []);
  
    // ${iOS() ? "h-[88vh]" : "h-[93vh]"} md:h-screen w-full overflow-hidden

    return (
      <Box
        id={RoutePattern.HOME}
        className={`relative flex flex-col justify-center items-center w-full py-10 ${iOS() ? "min-h-[88vh]" : "min-h-[93vh]"} md:min-h-screen`}
      >
        <div className="w-full md:w-[32%] mx-auto">
            <img
              src={TOUR_IMG} className="mt-5 md:mt-10"
            //   onClick={() => {
            //     window.location.href = "https://www.ticketmaster.com/bella-dose-tickets/artist/3054469";
            //   }}
            />
          </div>
          <div className="h-auto min-h-[1rem] mb-5 mt-10 md:mb-1 md:mt-10 w-full md:w-3/5 3xl:w-1/2 m-auto text-center">
              {/* <button
                className="bg-white text-black py-2 px-4 md:text-xl"
                onClick={() => {
                  window.location.href = "https://www.ticketmaster.com/bella-dose-tickets/artist/3054469";
                }}
              >GET TICKETS</button> */}
              
            <a className="bit-widget-initializer"
                
                data-artist-name="id_5268270"
                
                data-background-color="#000000"
                data-separator-color="#FFFFFF"
                data-text-color="#FFFFFF"
                data-font="Helvetica"
                data-auto-style="true"
                
                data-button-label-capitalization="capitalize"
                data-header-capitalization="undefined"
                data-location-capitalization="capitalize"
                data-venue-capitalization="capitalize"
                data-display-local-dates="true"
                data-local-dates-position="tab"
                data-display-past-dates="true"
                data-display-details=""
                data-display-lineup=""
                data-display-start-time=""
                data-social-share-icon="true"
                data-display-limit="all"
                
                data-date-format="MMM. D, YYYY"
                data-date-orientation="horizontal"
                data-date-border-color="#4A4A4A"
                data-date-border-width="1px"
                data-date-capitalization="undefined"
                data-date-border-radius="10px"
                
                data-event-ticket-cta-size="medium"
                data-event-custom-ticket-text="undefined"
                data-event-ticket-text="TICKETS"
                data-event-ticket-icon=""
                data-event-ticket-cta-text-color="#FFFFFF"
                data-event-ticket-cta-bg-color="#dd2726"
                data-event-ticket-cta-border-color="#dd2726"
                data-event-ticket-cta-border-width="0px"
                data-event-ticket-cta-border-radius="4px"
                
                data-sold-out-button-text-color="#FFFFFF"
                data-sold-out-button-background-color="#4A4A4A"
                data-sold-out-button-border-color="#4A4A4A"
                data-sold-out-button-clickable="true"
                
                data-event-rsvp-position="left"
                data-event-rsvp-cta-size="medium"
                data-event-rsvp-only-show-icon="undefined"
                data-event-rsvp-text="REMIND ME"
                data-event-rsvp-icon=""
                data-event-rsvp-cta-text-color="#000000"
                data-event-rsvp-cta-bg-color="#FFFFFF"
                data-event-rsvp-cta-border-color="#FFFFFF"
                data-event-rsvp-cta-border-width="1px"
                data-event-rsvp-cta-border-radius="4px"
                
                data-follow-section-position="top"
                data-follow-section-alignment="center"
                data-follow-section-header-text="Get updates on new shows, new music, and more."
                data-follow-section-cta-size="medium"
                data-follow-section-cta-text="FOLLOW"
                data-follow-section-cta-icon="true"
                data-follow-section-cta-text-color="#FFFFFF"
                data-follow-section-cta-bg-color="#4A4A4A"
                data-follow-section-cta-border-color="#4A4A4A"
                data-follow-section-cta-border-width="0px"
                data-follow-section-cta-border-radius="4px"
                
                data-play-my-city-position="bottom"
                data-play-my-city-alignment="Center"
                data-play-my-city-header-text="Don’t see a show near you?"
                data-play-my-city-cta-size="medium"
                data-play-my-city-cta-text="REQUEST A SHOW"
                data-play-my-city-cta-icon="true"
                data-play-my-city-cta-text-color="#FFFFFF"
                data-play-my-city-cta-bg-color="#4A4A4A"
                data-play-my-city-cta-border-color="#4A4A4A"
                data-play-my-city-cta-border-width="0px"
                data-play-my-city-cta-border-radius="4px"
                
                data-optin-font=""
                data-optin-text-color=""
                data-optin-bg-color=""
                data-optin-cta-text-color=""
                data-optin-cta-bg-color=""
                data-optin-cta-border-width=""
                data-optin-cta-border-radius=""
                data-optin-cta-border-color=""
                
                data-language="en"
                data-layout-breakpoint="900"
                data-app-id=""
                data-affil-code=""
                data-bit-logo-position="bottomRight"
                data-bit-logo-color="#CCCCCC"
                
                ></a>
          </div>
      </Box>
    );
  };
  
  export default Showcase;
  