import { Box, Image } from "../../components/StyledComponent";
import { RoutePattern } from "../../routes/RoutePattern";
// import MusicSlider from "../../components/MusicSlider/MusicSlider";
import { faBackward, faForward, faPlayCircle } from "@fortawesome/free-solid-svg-icons";

import { useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var Carousel = require( '3d-react-carousal').Carousel;

const data = [
  {
    title: 'L-Pop',
    img: 'https://linkstorage.linkfire.com/medialinks/images/b3ad3702-bd4f-4ca6-b9c3-72325775d94e/artwork-440x440.jpg',
    link: 'https://lnk.to/OSc2Anw1',
  },
  {
    title: 'Suelta',
    img: 'https://linkstorage.linkfire.com/medialinks/images/edd330b7-12a9-4b79-90b1-d5e3ac7595b9/artwork-440x440.jpg',
    link: 'https://lnk.to/mhOmeH52WE',
  },
  {
    title: '&nbsp; &nbsp; 143 &nbsp; &nbsp;',
    img: 'https://linkstorage.linkfire.com/medialinks/images/9a074737-ea43-44d9-8537-3420375d40ed/artwork-440x440.jpg',
    link: 'https://lnk.to/HBpNJO97WE',
  },
  {
    title: '(MAN)IFEST',
    img: 'https://linkstorage.linkfire.com/medialinks/images/3b2f5c59-3585-4763-bb65-5125654c1110/artwork-440x440.jpg',
    link: 'https://lnk.to/4sDwGR9dWE',
  },
  {
    title: 'Mirame',
    img: 'https://linkstorage.linkfire.com/medialinks/images/f8a6b36f-b118-4957-8a89-7b640d9e6218/artwork-440x440.jpg',
    link: 'https://lnk.to/IZmDIwG1WE',
  },
  {
    title: 'Talk Too Much',
    img: 'https://linkstorage.linkfire.com/medialinks/images/dc2590e4-baac-4756-aa7c-2235f9f09c6a/artwork-440x440.jpg',
    link: 'https://lnk.to/fZNkO9cAWE',
  },
  {
    title: '&nbsp; &nbsp; Bite &nbsp; &nbsp;',
    img: 'https://linkstorage.linkfire.com/medialinks/images/1159662c-998d-4343-9db1-b5dbe0ccdde9/artwork-440x440.jpg',
    link: 'https://lnk.to/veIfQVvLWE',
  },
  {
    title: 'Ex-Mas',
    img: 'https://linkstorage.linkfire.com/medialinks/images/09a3bf6c-abf6-49f1-91fb-4fd27ff89113/artwork-440x440.jpg',
    link: 'https://lnk.to/Og3dEVSNWE',
  },
  {
    title: '&nbsp; &nbsp; SHE &nbsp; &nbsp;',
    img: 'https://linkstorage.linkfire.com/medialinks/images/563689b8-55a6-4fc7-88f1-f325b8031b4e/artwork-440x440.jpg',
    link: 'https://lnk.to/lKeMhObjWE',
  },
  {
    title: 'Suelta',
    img: 'https://linkstorage.linkfire.com/medialinks/images/c785f398-bd2c-4b37-9919-f4bde532dc95/artwork-440x440.jpg',
    link: 'https://lnk.to/BTp4ySWXWE',
  },
];

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const Music = () => {
  const slides = data.map((item, idx) => {
    return (
      <div>
        <img
          key={idx}
          src={item.img}
          data-title={item.title}
          data-link={item.link}
        />
      </div>
    );
  });

  const handleClickArrow = (pos: any) => {
    $(`.slider-single.active .slider-${pos}`).trigger('click');
    $("#slide-title").html($(`.slider-single.${pos == 'left' ? 'preactive' : 'proactive'} img`).data('title'));
  };

  const handleClickPlay = () => {
    window.open($(".slider-single.active img").data('link'));
  };

  useEffect(() => {
    setTimeout(function() {
      $("#slide-title").html($(".slider-single.active img").data('title'));
    }, 1000);
  }, []);
  
  return (
    <Box
      id={RoutePattern.MUSIC}
      className="relative flex flex-col w-full"
    >
        <div
            className="text-4xl p-10 md:p-20 bg-red-600 text-center"
        >
            music
        </div>
        {/* <MusicSlider data={data} /> */}
        <div className={`flex flex-col ${iOS() ? "h-[40vh]" : "h-[34vh]"} md:h-[65vh] mt-8 md:mt-24 ${iOS() ? 'mb-20' : 'mb-28'} md:mb-40 3xl:mb-20 justify-between`}>
          <Carousel slides={slides} />
          <div className={`flex justify-center w-full h-12 ${iOS() ? 'pt-2' : 'pt-12'} 3xl:pt-0 3xl:pb-32`}>
            <button
             onClick={() => handleClickArrow('left')}
             className="text-3xl md:text-5xl mr-6 md:mr-16"
            >
              <FontAwesomeIcon icon={faBackward as any} />
            </button>
            <button
              onClick={handleClickPlay}
              className="text-3xl md:text-5xl mx-6 md:mx-16"
            >
              <FontAwesomeIcon icon={faPlayCircle as any} />
            </button>
            <button
             onClick={() => handleClickArrow('right')}
              className="text-3xl md:text-5xl ml-6 md:ml-16"
            >
              <FontAwesomeIcon icon={faForward as any} />
            </button>
          </div>
          <div id="slide-title" className="flex justify-center w-full absolute bottom-6 md:bottom-10 3xl:bottom-20 text-white text-2xl"></div>
        </div>
    </Box>
  );
};

export default Music;
