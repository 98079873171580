import { useState } from "react";
import { useEffect } from "react";
import { YoutubeItemTypes } from "./types";
import styled from "styled-components";
import { faAngleLeft, faAngleRight, faPlay, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Arrow } from "./styled.components";
import { isMobile } from "../../constants";
import Slider from "react-slick";
import PLAY_BUTTON from "../../assets/images/play.png";

// const data = [
//   {
//     videoId: "JYCpRdPkR1s",
//     title: "2023 Tour Recap (First Leg)",
//   },
//   {
//     videoId: "XvSuCnK2zl8",
//     title: "143",
//   },
//   {
//     videoId: "D6rhbrzvE34",
//     title: "(MAN)IFEST",
//   },
//   {
//     videoId: "aMhry1ojXRY",
//     title: "Mírame",
//   },
//   {
//     videoId: "hoVafSItJ74",
//     title: "Talk Too Much",
//   },
//   {
//     videoId: "dsXlLRFubCM",
//     title: "BITE",
//   },
//   {
//     videoId: "KtJQR44yxL4",
//     title: "Ex-Mas",
//   },
// ];

const data = [
  {
    videoId: "yd6CiR6uU70",
    title: "Windows (Official Video)",
  },
  {
    videoId: "5HI8biaAEco",
    title: "Crybaby (Official Video)",
  },
  {
    videoId: "O_A6RbSGzqE",
    title: "FFIL (Official Lyric Video) (Clean Version)",
  },
  {
    videoId: "v2CbwMmtTv0",
    title: "FFIL (Official Lyric Video)",
  },
  {
    videoId: "XvSuCnK2zl8",
    title: "143 (Official Music Video)",
  },
  {
    videoId: "hoVafSItJ74",
    title: "Talk Too Much (Official Music Video)",
  },
  {
    videoId: "dsXlLRFubCM",
    title: "BITE (Official Music Video)",
  },
  {
    videoId: "KtJQR44yxL4",
    title: "Ex-Mas (Official Music Video)",
  },
  {
    videoId: "h3x4ZBfOYIs",
    title: "She (Official Music Video)",
  },
  {
    videoId: "9PkLugNThOM",
    title: "Lárgate (EP Visualizer)",
  },
  {
    videoId: "seNQDBE7_ho",
    title: "Lárgate (Official Music Video)",
  },
  {
    videoId: "KfGiUV4q7cM",
    title: "Si Me Llamas (Official Music Video)",
  },
  {
    videoId: "p4jFvJRK7io",
    title: "Luna ft. DaniLeigh (Official Music Video)",
  },
  {
    videoId: "dPg074T_GvU",
    title: "Una Niña (Official Video)",
  },
  {
    videoId: "8s6gijt0h_4",
    title: "Pal Laito (Official Music Video)",
  },
  {
    videoId: "NHulkn3UKbA",
    title: "Hello (Official Music Video)",
  },
  {
    videoId: "vXbBkxgplJk",
    title: "Body Language (Official Music Video)",
  },
  {
    videoId: "9eQbPcDq9iU",
    title: "Christmas with u (Official Music Video)",
  },
  {
    videoId: "yHqp-jw_fmw",
    title: "holidays (Official Lyric Video)",
  },
  {
    videoId: "g3dEgTQl_Zc",
    title: "Comfortable (Official Video)",
  },
  {
    videoId: "gKdyJe3U1Gk",
    title: "What About Me (Official Video)",
  },
  {
    videoId: "L6kCnbr99VI",
    title: "MUEVELO (Official Music Video)",
  },
  {
    videoId: "nqF0Tn-S2ak",
    title: "Eyes on You (Official Video)",
  },
];

const Iframe = styled.iframe`
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 1rem !important;
`;

const VideoItem = styled.div`
  // max-width: 350px;
  // width: 100%;
  // height: 275px;

  @media (min-width: 768px) {
    height: 450px;
    max-width: 100%;
  }
`;

export default function SlickSlider() {
  const [youtubeItems] = useState<YoutubeItemTypes[]>(data);

  const items = youtubeItems.map((item, idx) => (
    <VideoItem
        key={idx}
        className="flex justify-center items-center flex-col text-white py-8 md:py-20"
    >
        {/* <img src={`https://i3.ytimg.com/vi/${item.videoId}/maxresdefault.jpg`} className="invisible absolute" /> */}
        <div
            className="w-[330px] md:w-[850px] h-[200px] md:h-[500px] flex flex-row justify-center items-center cursor-grab"
            style={{
                backgroundImage: `url('https://i3.ytimg.com/vi/${item.videoId}/maxresdefault.jpg')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div className="music-title">
                <a
                    href={`https://www.youtube.com/watch?v=${item.videoId}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-3xl md:text-5xl text-black"
                >
                    <img src={PLAY_BUTTON} className="w-[3rem] md:w-[5rem]" />
                </a>
            </div>
            <div className="music-title absolute mt-36 md:mt-60 text-sm md:text-2xl bg-black bg-opacity-50 px-2 whitespace-nowrap">"{item.title}"</div>
        </div>
    </VideoItem>
  ));

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    centerMode: true,
    variableWidth: true,
    touchThreshold: 100
  };

  const handleClick = (pos: any) => {
    $(`.slick-arrow.slick-${pos}`).trigger('click');
    // $("#slide-title").html($(`.slider-single.${pos == 'left' ? 'preactive' : 'proactive'} img`).data('title'));
  };

  return (
    <div className="md:min-h-[660px] overflow-x-hidden">
        <Slider {...settings}>
            {items}
        </Slider>
        
        <Arrow
            type="button"
            left="0"
            className="from-lef"
            onClick={() => handleClick('prev') }
        >
            <FontAwesomeIcon icon={faAngleLeft as any} />
        </Arrow>
        <Arrow
            type="button"
            right="0"
            className="from-righ"
            onClick={() => handleClick('next') }
        >
            <FontAwesomeIcon icon={faAngleRight as any} />
        </Arrow>
    </div>
  );
}
