import { Link as ScrollLink } from "react-scroll";
import { data } from "./data";
import SocialIcons from "../SocialIcons/SocialIcons";
import { Anchor, Box, Container, Text } from "../StyledComponent";
import FOOTER_LOGO from "../../assets/images/intl hub records logo.png";

export default function Footer() {
  const menuItems = data.map((item, idx, items) => {
    console.log(idx, items);
    return (
      <span>
        <a
          key={idx}
          href={item.to} // route
          target={item.target}
          className={`${item.class} mx-2 md:mx-3 text-shadow text-shadow text-sm`}
        >
          {item.name}
        </a>
        {idx + 1 !== items.length ? "|" : ""}
      </span>
    );
  });

  return (
    <Container
      id="footer"
      className="w-full px-5 md:px-24 py-5 mx-auto text-center bg-black"
    >
      <Text className="text-xs md:text-sm text-center mt-5 block">
        &copy; {new Date().getFullYear()}, BELLA DOSE
      </Text>
      <Box className="text-white hidden">{menuItems}</Box>
      <Box className="flex justify-center mt-5"><img src={FOOTER_LOGO} className="h-[3rem] md:h-[5rem]" /></Box>
      {/* <SocialIcons /> */}
    </Container>
  );
}
