import { RoutePattern } from "../../routes/RoutePattern";

export const data = [
  {
    name: RoutePattern.HOME,
    to: RoutePattern.HOME,
    isRoute: true,
  },
  {
    name: RoutePattern.VIDEOS,
    to: RoutePattern.VIDEOS,
    isRoute: true,
  },
  {
    name: RoutePattern.MUSIC,
    to: RoutePattern.MUSIC,
    isRoute: true,
  },
  // {
  //   name: RoutePattern.TOUR,
  //   to: RoutePattern.TOUR,
  //   isRoute: true,
  // },
  // {
  //   name: RoutePattern.PRESAVE,
  //   to: RoutePattern.PRESAVE,
  //   isRoute: true,
  // },
  {
    name: RoutePattern.STORE,
    to: "https://belladosecollections.com/",
  },
];
